/* General styles */
body {
    a {
        text-decoration: none;
    }
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px); /* Adjusting for navbar height */
    padding-top: 60px; /* Ensuring the login box doesn't overlap with the navbar */
}

.login-box {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
    margin-top: 60px; /* Adding space so it doesn't overlap with the navbar */
}

h2 {
    color: #333;
    margin-bottom: 20px;
}

.input-group {
    margin-bottom: 20px;
    text-align: left;
}

.input-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

.form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.form-input:focus {
    border-color: #258cfb;
    outline: none;
    box-shadow: 0 0 5px rgba(37, 140, 251, 0.5);
}

.btn-primary {
    background-color: #258cfb;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
}

.btn-primary:hover {
    background-color: #1861ac;
}

.error-message {
    color: red;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .login-box {
        padding: 20px;
        max-width: 100%;
    }
}

/* Navbar styles */
.navbar {
    background-color: #333;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
}

/* Container for title */
.navbar-title {
    margin-right: auto; /* Pushes this to the left */
}

.navbar h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    color: #fff;
}

/* Container for centered navigation */
.navbar-center {
    display: flex;
    justify-content: center; /* Center the navigation items */
    align-items: center;
    flex-grow: 1; /* Allow this to grow and take up space */
    margin: 0 auto; /* Center this container */
}

/* Styles for navigation links and buttons */
.navbar a, .navbar button {
    color: #fff;
    text-decoration: none;
    margin: 0 15px; /* Adjust margin for spacing between buttons */
    font-size: 16px;
}

.navbar a:hover, .navbar button:hover {
    text-decoration: underline;
}

.navbar button {
    background-color: #f44336;
    padding: 5px 10px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.navbar button:hover {
    background-color: #d32f2f;
}

.register-prompt {
    margin-top: 20px;
    font-size: 14px;
}

.register-prompt p {
    margin: 0;
}

.register-prompt a {
    color: #258cfb;
    text-decoration: none;
}

.register-prompt a:hover {
    text-decoration: underline;
}

/* General styles for register page, same as login */
.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
    padding-top: 60px;
}

.register-box {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
    margin-top: 60px;
}

.input-group {
    margin-bottom: 20px;
    text-align: left;
}

.input-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

.form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.form-input:focus {
    border-color: #258cfb;
    outline: none;
    box-shadow: 0 0 5px rgba(37, 140, 251, 0.5);
}

.btn-primary {
    background-color: #258cfb;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
}

.btn-primary:hover {
    background-color: #1861ac;
}

.error-message {
    color: red;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .register-box {
        padding: 20px;
        max-width: 100%;
    }
}

.validation-error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.validation-error ul {
    list-style-type: none;
    padding: 0;
}

.validation-error li {
    margin: 0;
}

/* Profile page styling */
.profile-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    background-color: #f4f7fc;
    min-height: 100vh;
}

.profile-box {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
}

.profile-section {
    margin-bottom: 20px;
}

.profile-section h3 {
    font-size: 1.5em; /* Larger font size for section headings */
    margin-bottom: 10px;
}

.form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.btn-primary {
    background-color: #258cfb;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
}

.btn-primary:hover {
    background-color: #1861ac;
}

.btn-secondary {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
}

.btn-secondary:hover {
    background-color: #d32f2f;
}

.success-text {
    color: green;
}

.message-text {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
}

/* Button styles */
.btn-outline {
    background-color: transparent;
    color: #258cfb;
    border: 2px solid #258cfb;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 10px;
}

.btn-outline:hover {
    background-color: #258cfb;
    color: white;
}

.btn-danger {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
}

.btn-danger:hover {
    background-color: #d32f2f;
}

.subscription-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.subscription-status-badge {
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 12px;
    color: #0e0909;
}

.subscription-status-badge.active {
    background-color: #28a745; /* Green for active subscriptions */
    color: #e60707; /* White text */
}

.subscription-status-badge.expiring {
    background-color: #ffc107; /* Yellow for expiring soon */
    color: #212529; /* Dark text for readability */
}

.subscription-status-badge.expired {
    background-color: #dc3545; /* Red for expired subscriptions */
    color: #fff; /* White text */
}

/* Dashboard styles */
.dashboard-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    background-color: #f4f7fc;
    min-height: 100vh;
}

.dashboard-box {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    width: 100%;
}

.listings-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.listings-table th, .listings-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.preferences-list {
    margin-top: 20px;
    padding: 0;
    list-style: none;
}

.preferences-list li {
    display: flex;
    justify-content: space-between; /* Ensures even spacing between text and buttons */
    align-items: center; /* Vertically aligns buttons with the text */
    margin-bottom: 10px;
}

.preferences-list li .btn-wrapper {
    display: flex;
    gap: 10px; /* Space between buttons */
}

.preferences-list li button {
    min-width: 80px; /* Ensures buttons have the same width */
}

.btn-primary, .btn-secondary, .btn-danger {
    padding: 10px 20px;
    border-radius: 5px;
}

.btn-primary {
    background-color: #258cfb;
    color: white;
    border: none;
}

.btn-secondary {
    background-color: #f0ad4e;
    color: white;
    border: none;
}

.btn-danger {
    background-color: #d9534f;
    color: white;
    border: none;
}

.btn-standard {
    min-width: 150px;
    margin: 5px;
}

.btn-primary:hover, .btn-secondary:hover, .btn-danger:hover {
    opacity: 0.9;
}

/* General styles */
.home-container {
    text-align: center;
    padding: 20px;
    background-color: #f4f7fc;
}

/* Hero Section */
.hero-section {
    background-color: #258cfb;
    color: white;
    padding: 60px 20px;
    border-radius: 10px;
    margin-bottom: 40px;
}

.hero-section h1 {
    font-size: 40px;
    margin-bottom: 20px;
}

.hero-section p {
    font-size: 18px;
    margin-bottom: 40px;
}

.hero-section .btn-primary {
    background-color: white;
    color: #258cfb;
    padding: 15px 30px;
    border-radius: 5px;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.hero-section .btn-primary:hover {
    background-color: #1861ac;
    color: white;
}

/* How It Works Section */
.how-it-works-section {
    padding: 60px 20px;
}

.how-it-works-section h2 {
    font-size: 36px;
    margin-bottom: 40px;
}

.how-it-works {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 40px;
}

.how-it-works .step {
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    width: 300px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.how-it-works .step h3 {
    font-size: 22px;
    margin-bottom: 20px;
}

/* Subscription Tiers Section */
.subscription-tiers-section {
    padding: 60px 20px;
}

.subscription-tiers-section h2 {
    font-size: 36px;
    margin-bottom: 40px;
}

.subscription-tiers-section p {
    font-size: 18px;
    margin-bottom: 30px;
}

.tiers {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 40px;
}

.tier-card {
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    width: 280px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.tier-card h3 {
    font-size: 24px;
    margin-bottom: 15px;
}

.tier-card ul {
    list-style-type: none;
    padding: 0;
}

.tier-card ul li {
    margin-bottom: 10px;
}

/* Testimonials Section */
.testimonials-section {
    padding: 60px 20px;
    background-color: #f4f7fc;
}

.testimonials-section h2 {
    font-size: 36px;
    margin-bottom: 40px;
}

.testimonials {
    display: flex;
    justify-content: space-around;
    gap: 40px;
}

.testimonial {
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    width: 400px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.testimonial p {
    font-size: 16px;
    margin-bottom: 20px;
}

.testimonial strong {
    font-size: 18px;
}

/* Main container to centralize content */
.main-container {
    max-width: 1200px;
    margin: 0 auto; /* Center-aligns the content */
    padding: 20px;
}

/* Reduce header and footer padding */
.navbar, .footer-section {
    padding: 10px 20px;
}

.hero-section {
    padding: 40px 20px;
}

/* Minimize the gaps between sections */
.how-it-works-section, .subscription-tiers-section, .testimonials-section {
    padding: 40px 20px;
}

/* Subscription tiers */
.tiers {
    display: flex;
    justify-content: space-between; /* Ensure equal spacing */
    gap: 20px;
}

.tier-card {
    flex: 1; /* Allow cards to evenly share space */
    margin: 0 10px; /* Reduce the space between cards */
}

/* Ensure footer and header don't feel stretched */
.footer-section {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px;
    width: 100%;
    position: relative;
}

/* Button alignment and padding adjustments */
.btn-primary {
    width: 250px;
    margin: 10px auto; /* Center-aligns the buttons */
    padding: 10px 20px;
}

.testimonials-section, .how-it-works-section {
    margin-bottom: 20px;
}

.navbar {
    background-color: #f8f9fa; /* Light background color for the navbar */
}

.navbar-brand span {
    color: #333; /* Dark text color for brand name */
}

.navbar-nav .nav-link {
    color: #333; /* Dark text color for links */
}

.navbar-nav .nav-link:hover {
    color: #007bff; /* Blue color on hover */
}

.btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
}

.btn-outline-primary:hover {
    background-color: #007bff;
    color: #fff;
}

.hero-section {
    position: relative;
    text-align: center;
}

.hero-section .overlay {
    max-width: 400px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.registration-form input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.registration-form button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 12px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.registration-form button:hover {
    background-color: #0056b3;
}

.card {
    border: none;
    border-radius: 10px;
}

.card-body {
    background-color: #e7f3ff; /* Light background color for emphasis */
    border-radius: 10px;
    padding: 20px;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.progress {
    height: 20px;
    border-radius: 10px;
}

.progress-bar {
    background-color: #258cfb;
}

.ml-3 {
    margin-left: 1rem !important;
}

.flex-grow-1 {
    flex-grow: 1;
}

/* Blurred background effect when the subscription prompt is active */
/* Blurred background effect when the subscription prompt is active */
/* Blurred background effect when the subscription prompt is active */
.dashboard-blurred {
    filter: blur(5px);
    pointer-events: none; /* Prevents interaction with the blurred content */
}

/* Overlay to darken the background */
.subscription-prompt-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2000; /* Ensure the overlay is above the blurred content */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Content box for the subscription prompt */
.subscription-prompt-content {
    position: relative; /* Ensures the prompt remains positioned properly */
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    max-width: 1100px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    z-index: 3000; /* Higher z-index to make sure it's on top of the overlay */
}


.subscription-plans {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    gap: 20px;
}

.subscription-plan-card {
    width: 32%; /* Slightly wider for better text spacing */
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subscription-plan-card ul {
    list-style: none;
    padding: 0;
    text-align: left;
    margin: 10px 0;
}

.subscription-plan-card li {
    margin: 6px 0;
    font-size: 1rem;
}

.align-button {
    width: 100%; /* Ensures all Subscribe buttons align properly */
    margin-top: 15px;
}

.start-free-trial-btn {
    width: 100%; /* Full width for better alignment */
    text-align: center;
    margin-top: 5px;
    color: #007bff;
    font-size: 0.9rem;
}

.subscription-cta-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.wider-button {
    width: 200px; /* Increased button width for better visibility */
}

.loading-spinner {
    height: 100vh; /* Full screen height */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Responsive adjustments for subscription plans */
@media (max-width: 768px) {
    .subscription-plans {
        flex-direction: column; /* Stack the plans vertically */
        align-items: center; /* Center-align the plans */
    }

    .subscription-plan-card {
        width: 90%; /* Make each plan take up most of the screen width */
        margin-bottom: 20px; /* Add space between the cards */
    }
}

/* Ensure the modal content scales properly on smaller screens */
@media (max-width: 480px) {
    .subscription-prompt-content {
        max-width: 95%; /* Reduce width to fit smaller screens */
        padding: 20px; /* Adjust padding for smaller devices */
    }

    .subscription-plan-card ul {
        font-size: 0.9rem; /* Adjust text size for better readability */
    }
}

/* Ensure that the subscription prompt content is scrollable on smaller screens */
@media (max-width: 768px) {
    .subscription-prompt-content {
        max-height: 80vh; /* Limit the maximum height of the popup */
        overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
        padding: 20px; /* Reduce padding to fit content better on small screens */
    }
}

.modal-footer .btn {
    margin: 5px; /* Ensure even spacing between buttons */
    padding: 10px 20px; /* Consistent padding for a balanced look */
    flex: 1; /* Make sure buttons take up equal space */
    text-align: center;
}

.text-muted-upgrade {
    color: #6c757d;
    font-weight: bold;
    background-color: #f8f9fa;
    padding: 10px;
    text-align: center;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

/* Style to blur out additional listings */
.blurred-row {
    filter: blur(5px);
    opacity: 0.5;
}

/* Blurred overlay styling */
.blurred-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.95); /* Light background with transparency */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    z-index: 1000; /* Ensure the overlay stays on top */
    width: 80%; /* Make the overlay width adjust to fit the content */
    max-width: 600px; /* Prevent the overlay from becoming too wide */
}

.upgrade-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding: 1em;
    width: 100%;
}

.matched-listings-container {
    position: relative; /* Ensure that the container holds the overlay */
    max-width: 1000px; /* Ensure the width of the listings container matches the alert */
    margin: 0 auto; /* Center the listings container within the page */
}

/* Ensure the alert stretches to match the container width */
.alert-info {
    width: 100%; /* Make the alert full-width within its parent container */
    max-width: 1000px; /* Adjust max-width to match the layout of other elements */
    margin: 0 auto; /* Center the alert within the container */
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    background-color: #e7f3fe; /* Light blue background to make it distinct */
    border: 1px solid #b3d7ff; /* Border to keep the styling consistent */
    border-radius: 8px; /* Keep the rounded corners consistent with the design */
}

.alert-info .btn-primary {
    margin-top: 10px;
}


.city-suggestions-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    position: absolute;
    width: 94%;
    z-index: 1000;
}

.city-suggestion-item {
    padding: 10px;
    cursor: pointer;
}

.city-suggestion-item:hover {
    background-color: #f1f1f1;
}


/* Import Tailwind CSS base styles */
@import 'tailwindcss/base';

/* Import Tailwind CSS components */
@import 'tailwindcss/components';

/* Import Tailwind CSS utilities */
@import 'tailwindcss/utilities';


.dwellio-nav {

    a {
        text-decoration: none !important;
    }
;

}

.blurred-content {
    filter: blur(4px);
    user-select: none;
    pointer-events: none;
}

.bg-opacity-80 {
    --bs-bg-opacity: 0.8;
}

@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-fade-in {
    animation: fade-in 0.3s ease-out;
}

.dwellio-footer ul {
    padding-left: 0;
}

.dwellio-footer ul li a {
    color: #fff;
}

/* Custom.css */
.dwellio-nav {
    position: relative;
    height: 64px;
}

.dwellio-mobile-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dwellio-mobile-nav__left {
    display: flex;
    align-items: center;
}

.dwellio-mobile-nav__center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}

.dwellio-mobile-nav__right {
    display: flex;
    align-items: center;
}

.dwellio-mobile-menu {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    height: calc(100vh - 64px);
    background-color: white;
    z-index: 50;
    overflow-y: auto;
}

.dwellio-mobile-menu__section {
    border-bottom: 1px solid #f3f4f6;
    padding: 1rem;
}

.dwellio-mobile-menu__title {
    font-size: 0.875rem;
    font-weight: 600;
    color: #6b7280;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
}

.dwellio-mobile-menu__item {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    color: #1f2937;
    font-size: 1rem;
}

.dwellio-mobile-menu__item:active {
    background-color: #f3f4f6;
}

@media (max-width: 768px) {
    .dwellio-mobile-heading {
        font-size: 1.125rem; /* 18px */
        margin-bottom: 0.5rem; /* 8px */
        font-weight: 600;
    }

    .dwellio-mobile-form-group {
        margin-bottom: 0.5rem; /* 8px */
    }

    .dwellio-mobile-label {
        font-size: 0.875rem; /* 14px */
        margin-bottom: 0.25rem; /* 4px */
        color: #374151; /* Tailwind gray-700 */
    }

    .dwellio-mobile-input {
        padding: 0.375rem; /* 6px */
        font-size: 0.875rem; /* 14px */
        border-radius: 0.375rem; /* 6px */
        border-width: 1px;
        border-color: #d1d5db; /* Tailwind gray-300 */
        background-color: #ffffff;
        width: 100%; /* Ensure full width */
    }

    .dwellio-mobile-input:focus {
        outline: none;
        ring-width: 2px;
        ring-color: #3b82f6; /* Tailwind blue-500 */
        border-color: transparent;
    }

    .dwellio-mobile-input.border-red-500 {
        border-color: #ef4444; /* Tailwind red-500 */
        background-color: #fef2f2; /* Tailwind red-50 */
    }

    .dwellio-mobile-alert {
        padding: 0.375rem; /* 6px */
        margin-bottom: 0.5rem; /* 8px */
        border-radius: 0.375rem; /* 6px */
        gap: 0.375rem; /* 6px */
    }

    .dwellio-mobile-error {
        font-size: 0.75rem; /* 12px */
        margin-top: 0.25rem; /* 4px */
        color: #ef4444; /* Tailwind red-500 */
    }

    .dwellio-mobile-button {
        padding: 0.375rem 0.75rem; /* 6px 12px */
        font-size: 0.875rem; /* 14px */
        border-radius: 0.375rem; /* 6px */
    }

    /* Ensure grid layout for price range and bedrooms uses full width and columns */
    .dwellio-mobile-form-group.grid {
        width: 100%;
        grid-template-columns: 1fr 1fr; /* Equal columns for side-by-side layout */
        gap: 0.5rem; /* Consistent gap */
    }
}

@media (max-width: 768px) {
    .dwellio-mobile-large-button {
        padding: 0.75rem 1.5rem; /* 12px 24px - larger padding for a bigger button */
        font-size: 1rem; /* 16px - larger text for visibility */
        border-radius: 0.375rem; /* 6px - consistent with other elements */
        width: 100%; /* Full width to match Rightmove’s button */
        background-color: #2563eb; /* Tailwind teal-500 - matches Rightmove’s teal color */
        border: none; /* Remove border for a cleaner look */
    }

    .dwellio-mobile-large-button:hover {
        background-color: #1d4ed8; /* Tailwind teal-600 - darker shade on hover */
    }

    .dwellio-mobile-large-button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
